<template>
  <wt-tooltip>
    <template v-slot:activator>
      <wt-icon-btn
        v-bind="$attrs"
        class="table-action"
        icon="download"
        @click="$emit('click')"
      ></wt-icon-btn>
    </template>
      {{ $t('iconHints.download') }}
  </wt-tooltip>
</template>

<script>
export default {
  name: 'download-action',
};
</script>

<style scoped>

</style>
